<template>
  <div>
    <div class="vs-row height">
      <img class="w-full h-full" :src="product.image" alt="">
    </div>
    <div class="mx-7 m-auto">
      <div class="divide-y divide-gray-300 mx-3">
        <div class="vs-row mt-5 pb-16">
          <div class="vs-col sm:w-1/2 w-full">
            <p class="text-lg">{{product.name_en}}</p>
            <div class="my-3 flex">
              <img class="w-7" src="../assets/images/cash.svg" alt=""><span class="text-blue line-through ml-2">{{product.pre_price}} KWD</span> <span class="text-orange  ml-2">{{product.price}} KWD</span>
            </div>
          </div>
          <div class="vs-col sm:w-1/2 sm:mb-0 w-full mb-5 flex justify-end">
            <vs-button class="btn ml-3" color="#00DCDC" text-color="#fff" icon="reply">Share</vs-button>
          </div>
        </div>
        
        <div class="activity-detail vs-row pt-7">
          <div class="vs-col md:w-2/3 w-full divide-y divide-gray-300 md:pr-16 text-lg">
            <div class="vs-row mb-5">
              <div class="vs-row text-gray-600">
                <div v-html="product.description_en"></div>
              </div>
              <div v-if="product.colors && product.colors.length > 0" class="vs-row text-gray-600">
                <p class="mt-6">colors</p>
                <ul class="my-4 inline-flex" v-for="color in product.colors" :key="color.index" >
                  <li class="mr-8">
                    <vs-radio v-model="book.color" :vs-value="color">
                      <div class="border w-8 h-8 rounded-full" :style="`background: ${color}`"></div>
                    </vs-radio>
                  </li>
                </ul>
                <!-- <div class="flex my-4">
                  <div   class=" w-4 h-4 rounded-full mr-8 flex"></div>
                </div> -->
              </div>
            </div>
            <div v-if="product.sizes && product.sizes.length > 0" class="vs-row text-gray-600">
              <p class="mt-6">Sizes</p>
              <ul class="flex my-4">
                <li v-for="size in product.sizes" :key="size.index" class="mr-8">
                  <vs-radio v-model="book.size" :vs-value="size">{{size}}</vs-radio>
                </li>
              </ul>
            </div>
            <div class="vs-row">
              <div class="border rounded my-10 pb-7 px-2 py-3 flex">
                <vs-icon icon="local_shipping" size="55px" color="primary" class="border-b-4" style="	border-color:#00DCDC"></vs-icon>
                <div v-if="$route.params.lang === 'en'" class="ml-5 self-center" v-html="product.dn_en"></div>
                <div v-else class="ml-5 self-center" v-html="product.dn_ar"></div>
              </div>
            </div>
          </div>
          <div class="vs-col md:w-1/3 w-full">
            <vs-card>
              <div class="px-3 text-lg">
                <div class="divide-y divide-gray-100">
                  <div class="mb-4">
                    <div class="vs-row flex justify-end">
                      <img class="w-11" style="margin-top: -10px;" src="@/assets/images/discountpadge.svg" alt="">
                      <span class="absolute pl-1 ml-2 text-white text-sm">{{product.discount_percent}}</span>
                    </div>
                    <div class="flex">
                      <span class="self-center time mr-1 text-blue">Special Order</span>
                      <vs-icon class="material-icons-outlined self-center" color="warning" icon="info"></vs-icon>
                    </div>
                    <p class="mt-1">{{product.name_en}}</p>
                    <div class="flex ">
                      <img class="w-7" src="../assets/images/cash.svg" alt=""><span class="text-blue line-through ml-2">{{product.pre_price}} KWD</span> <span class="text-orange  ml-2">{{product.price}} KWD</span>
                    </div>
                  </div>
                  <div class="pt-4">
                    <vs-input-number color="#00DCDC" min="0" :max="Number(product.qty)" v-model="book.quantity"/>
                  </div>
                </div>
                <div class="flex text-sm">
                  <div class="divide-x divide-gray-300 text-gray-500 self-center text-base">
                    <!-- <span class="pr-2">At {{product.location[0].branch.area_name}}</span>
                    <span class="px-2">{{product.age_from}}-{{product.age_to}} Years</span>
                    <span class="pl-2">{{interestsDic[product.interests_id]}}</span> -->
                  </div>
                </div>
                <div class="mt-1">
                  <!-- <span class="text-blue">Price: </span><span class="text-orange">{{product.location[0].price}} KWD</span> -->
                </div>
                <div class="vs-row my-5">
                  <vs-button @click="addToCart()" class="btn w-full" color="warning" text-color="#fff">Total : {{book.quantity * product.price}} KWD | Add To Cart</vs-button>
                </div>
              </div>
            </vs-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios.js'

export default {
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      book: {
        quantity: 0
      },
      interestsDic: {},
      product: {},
    }
  },
  methods: {
    loadProductDetail () {
      this.$vs.loading()
      axios.get(`v2/category/${this.$route.params.categoryId}/products`)
      .then((response) => {
        const product = response.data.payload.data.filter(item => item.id == this.$route.params.productId)
        this.product = product[0]
        this.book.size = this.product.sizes ? this.product.sizes[0] : null
        this.book.color = this.product.colors ? this.product.colors[0] : null
        this.$vs.loading.close()
      })
    },
    loadInterests () {
      this.$vs.loading()
      axios.get('v1/interestsList', {}, {headers: this.headers})
      .then(response => {
        response.data.payload.forEach(element => {
          this.interestsDic[element.interests_id] = element.name
        })
        this.$vs.loading.close()
      })
    },
    addToCart () {
      let cart = JSON.parse(localStorage.getItem('cart'))
      this.product.book = this.book
      cart.push(this.product)
      localStorage.setItem('cart', JSON.stringify(cart))
      this.$vs.notify({
        color:'success',
        title: 'Success',
        text: 'This product has been added to your cart!',
        position: 'top-center'
      })
    }
  },
  created () {
    this.loadProductDetail()
  }
}
</script>


<style lang="scss" scoped>
.vs-input-number {
  font-size: x-large;
  background: none;
}
.vueperslides__arrow svg {
  color: black !important;
}
.height {
  height: 500px;
}
.swiper {
  height: 400px;
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .swiper-button-prev, .swiper-button-next {
    background-color: #F89416;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    color: #fff !important;
    font-size: small;
  }
}
</style>

<style scoped>
.image {
  width: 100%;
  height: 200px;
  display: block;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color: gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.btn {
  padding: 7px 20px;
  border-radius: 2px;
}
.thumbnails {
  margin: auto;
  max-width: 100%;
  margin-top: 10px;
}
.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 2px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}
.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #00DCDC;
}
</style>